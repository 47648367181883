import cn from 'classnames';
import { FC } from 'react';
import styles from './full-screen-loader.module.scss';
import { AnimatedLogo } from '../AnimaterdLogo';

export type ScreenLoaderProps = {
  /**
   * When to show Spinner. (default: true)
   */
  loading?: boolean;
  /**
   * Text to show when loading
   */
  loadingText?: React.ReactNode;
  /**
   * Size of loading icon.
   */
  size?: number;
  /**
   * ClassName for styling.
   */
  className?: string;
};

const ScreenLoader: FC<ScreenLoaderProps> = ({ size = 40, loading = true, loadingText = 'Loading...', className }) => {
  if (loading === false) {
    return null;
  }

  return (
    <div className={cn(styles.screenLoader, className)}>
      <AnimatedLogo />
      <div style={{ marginTop: 8 }}>{loadingText}</div>
    </div>
  );
};

export default ScreenLoader;
