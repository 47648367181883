import styles from './full-screen-loader.module.scss';
import { AnimatedLogo } from '../AnimaterdLogo';

export type FullScreenLoaderProps = {
  /**
   * Full screen background color. Should be a valid color (hex, rgba, var(--gray-1)).
   */
  backgroundColor?: string;
  /**
   * When to show Spinner. (default: true)
   */
  loading?: boolean;
  /**
   * Text to show when loading
   */
  loadingText?: React.ReactNode;
  /**
   * Size of loading icon.
   */
  size?: number;
  /**
   * Color of the loading Text. Should be a valid color (hex, rgba, var(--gray-1)).
   */
  textColor?: string;
};

export const FullScreenLoaderSemiTransparentTheme = {
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  textColor: 'var(--gray-1)',
};

export const FullScreenLoader = ({
  backgroundColor = '#fff',
  loading = true,
  loadingText = 'Loading...',
  size = 56,
  textColor = 'var(--gray-9)',
}: FullScreenLoaderProps) => {
  if (loading === false) {
    return null;
  }

  return (
    <div style={{ backgroundColor }} className={`sailor-full-screen-loader ${styles.fullScreenLoaderWrapper}`}>
      <div className={styles.fullScreenLoader}>
        <AnimatedLogo size="medium" />
        <div style={{ marginTop: 8, color: textColor }}>{loadingText}</div>
      </div>
    </div>
  );
};
