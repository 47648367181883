import { FC } from 'react';
import MdcLogoWhite from './MdcLogoWhite';
import styles from './styles.module.scss';

interface Props {
  size?: 'small' | 'medium' | 'large';
}

const sizeMap = new Map<string, { width: number; height: number; strokeWidth: number }>([
  ['small', { width: 23, height: 15, strokeWidth: 2 }],
  ['medium', { width: 33, height: 21, strokeWidth: 3 }],
  ['large', { width: 53, height: 33, strokeWidth: 4 }],
]);

export const AnimatedLogo: FC<Props> = ({ size = 'medium' }) => {
  // @ts-ignore
  const { width, height, strokeWidth } = sizeMap.get(size) || sizeMap.get('medium');

  return (
    <div className={styles.wrapper} style={{ width: width + 20, height: width + 20, backgroundColor: '#1e00d5' }}>
      <MdcLogoWhite height={height} width={width} strokeWidth={strokeWidth} />
    </div>
  );
};
