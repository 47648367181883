/* eslint-disable max-len */
import * as React from 'react';
import styles from './styles.module.scss';

interface Props {
  height?: number;
  width?: number;
  strokeWidth?: number;
}

const MdcLogoWhite: React.FC<Props> = ({ height = 21, strokeWidth = 2, width = 33 }) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 57 35">
    <path
      className={styles.animatedPath}
      d="M45.4524 13.3896C44.9017 10.8258 43.3759 8.57664 41.1974 7.11749C39.0189 5.65834 36.3585 5.10355 33.7785 5.57038C32.79 5.75067 31.834 6.07785 30.9422 6.54106C29.8231 4.98001 28.3479 3.70837 26.639 2.83166C24.9301 1.95495 23.0368 1.49844 21.1162 1.5H20.7576C17.7184 1.59271 14.8255 2.82676 12.6549 4.95646C10.4843 7.08616 9.19523 9.95532 9.04433 12.9927C9.04433 13.0644 9.03835 13.135 9.03715 13.2055C6.96872 13.7902 5.13368 15.0046 3.78685 16.68C2.44002 18.3554 1.64825 20.4088 1.52151 22.5548C1.51767 22.5881 1.51568 22.6217 1.51554 22.6552C1.51554 22.7341 1.50717 22.8154 1.50598 22.8943C1.50598 22.9995 1.5 23.1071 1.5 23.2135C1.49791 25.9064 2.55242 28.4927 4.4368 30.4163C6.32118 32.3398 8.88499 33.4471 11.577 33.5H35.5068C40.8388 33.4971 45.9515 31.3774 49.7218 27.6066C53.492 23.8358 55.6114 18.7223 55.6143 13.3896H45.4524Z"
      stroke="#fff"
      strokeWidth={strokeWidth}
    />
  </svg>
);

const MemoMdcLogoWhite = React.memo(MdcLogoWhite);

export default MemoMdcLogoWhite;
